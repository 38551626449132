/*
    C-TXT
    v0.2.0
    Composant générique CSS ITADS
    https://github.com/ita-design-system/c-txt.scss
*/
// SCSS map
$briks-components-generic: ( 
    // Nom du composant
    txt: ( 
        // Composant activé true ou false
        enabled: true, 
        // Responsive activé true ou false
        responsive: true, 
        // Liste des propriétés c-txt par défaut
        defaults: (),
        // Rendu: 
        // c-txt {
        // }
        // Liste des modifieurs contenant chacun une liste de propriétés qui 
        // soit surchargent les propriétés par défaut
        // soit ajoutent des propriétés
        // soit les deux
        modifiers: ( 
            // HYPHENS Césures
            // https://developer.mozilla.org/en-US/docs/Web/CSS/hyphens
            hy-auto: (
                hyphens: auto
            ),
            hy-manual: (
                hyphens: manual
            ),
            hy-none: (
                hyphens: none
            ),
            // FONT WEIGHT
            // https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight#common_weight_name_mapping
            // 100 	Thin (Hairline)
            // 200 	Extra Light (Ultra Light)
            // 300 	Light
            // 400 	Normal
            // 500 	Medium
            // 600 	Semi Bold (Demi Bold)
            // 700 	Bold
            // 800 	Extra Bold (Ultra Bold)
            // 900 	Black (Heavy)
            fw-900: (
                font-weight: 900
            ),
            fw-800: (
                font-weight: 800
            ),
            fw-700: (
                font-weight: 700
            ),
            fw-600: (
                font-weight: 600
            ),
            fw-500: (
                font-weight: 500
            ),
            fw-400: (
                font-weight: 400
            ),
            fw-300: (
                font-weight: 300
            ),
            fw-200: (
                font-weight: 200
            ),
            fw-100: (
                font-weight: 100
            ),
            // TEXT ALIGN
            // c-txt m-ta-left
            ta-left: (
                text-align: left
            ),
            // c-txt m-ta-center
            ta-center: (
                text-align: center
            ),
            // c-txt m-ta-right
            ta-right: (
                text-align: right
            ),
            // c-txt m-ta-justify
            ta-justify: (
                text-align: justify
            ),
            // TEXT DECORATION
            // c-txt m-td-underline
            td-underline: (
                text-decoration: underline
            ),
            // c-txt m-td-none
            td-none: (
                text-decoration: none
            ),
            // c-txt m-td-line-through
            td-line-through: (
                text-decoration: line-through
            ),
            // TEXT TRANSFORM
            // c-txt m-tt-uppercase
            tt-uppercase: (
                text-transform: uppercase
            ),
            // c-txt m-tt-lowercase
            tt-lowercase: (
                text-transform: lowercase
            ),
            // FONT STYLE
            // c-txt m-fstyle-italic
            fstyle-italic: (
                font-style: italic
            ),
            // c-txt m-fstyle-normal
            fstyle-normal: (
                font-style: normal
            ),
            // ELLIPSE 1 ligne
            // Requiert c-dis et c-dim pour fonctionner
            // c-txt m-ellipsis c-dim m-o-hidden
            ellipsis: (
                white-space: nowrap,
                text-overflow: ellipsis
            ),
            // ELLIPSE 2 lignes
            // A utiliser avec c-txt et c-dim
            // c-dis m-webkit-box
            // c-dim m-o-hidden
            // c-txt m-ellipsis-2
            ellipsis-2: (
                text-overflow: ellipsis,
            ),
            // ELLIPSE 3 lignes
            // A utiliser avec c-txt et c-dim
            // c-dis m-webkit-box
            // c-dim m-o-hidden
            // c-txt m-ellipsis-3
            ellipsis-3: (
                text-overflow: ellipsis
            ),
            // LINE HEIGHT
            // c-txt m-lh-0
            lh-0:(
                line-height: 0
            ),
            // c-txt m-lh-1
            lh-1:(
                line-height: 1em
            ),
            // c-txt m-lh-2
            lh-2:(
                line-height: 1.2em
            ),
            // c-txt m-lh-3
            lh-3:(
                line-height: 1.3em
            ),
            // c-txt m-lh-4
            lh-4:(
                line-height: 1.4em
            ),
            // c-txt m-lh-5
            lh-5:(
                line-height: 1.5em
            ),
            // c-txt m-lh-6
            lh-6:(
                line-height: 1.6em
            ),
            // c-txt m-lh-7
            lh-7:(
                line-height: 1.7em
            ),
            // c-txt m-lh-8
            lh-8:(
                line-height: 1.8em
            ),
            // c-txt m-lh-8
            lh-9:(
                line-height: 1.9em
            ),
            // VERTICAL ALIGN
            // c-txt m-va-top
            va-top: (
                vertical-align: top
            ),
            // c-txt m-va-middle
            va-middle: (
                vertical-align: middle
            ),
            // c-txt m-va-bottom
            va-bottom: (
                vertical-align: bottom
            ),
            // c-txt m-va-super
            va-super: (
                vertical-align: super
            ),
            // c-txt m-va-sub
            va-sub: (
                vertical-align: sub
            ),
            // CÉSURES
            // c-txt m-wb-break-all
            wb-break-all: (
                word-break: break-all
            ),
            // c-txt m-wb-break-word
            wb-break-word: (
                word-break: break-word
            ),
            // RETOURS À LA LIGNE
            // c-txt m-ws-nowrap
            ws-nowrap: (
                white-space: nowrap
            ),
            // LISSAGE TYPO
            antialiased: (
                -webkit-font-smoothing: antialiased,
                -moz-osx-font-smoothing: grayscale
            )
        )
    )
);